import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Money } from "../components/l10n"
import SquareImage from "./squareimage"
import { IProduct } from "../graph"

const Container = styled(Link)`
  width: 300px;
  margin: 30px;
  @media (max-width: 1339px) {
    width: 250px;
  }
  @media (max-width: 699px) {
    width: 100%;
    display: flex;
  }
  @media (max-width: 399px) {
    margin: 30px 15px 0;
  }
`
const Image = styled(SquareImage)`
  width: 30vw;
  @media (min-width: 700px) {
    width: 250px;
  }
  @media (min-width: 1340px) {
    width: 300px;
  }
`
const Text = styled.div`
  @media (max-width: 699px) {
    margin-left: 15px;
  }
`
const Title = styled.h2`
  margin: 15px 0 8px;
  @media (max-width: 375px) {
    margin-top: 5px;
  }
`
const Price = styled.div`
  font-size: 18px;
`
const Subtitle = styled.div`
  margin-bottom: 8px;
  color: #9b9b9b;
`

interface Props {
  product: IProduct
}

const Product = ({ product }: Props) => {
  return (
    <Container to={`/product/${product.slug}/`}>
      <Image fluid={product.photos[0].fluid} />
      <Text>
        <Title>{product.name}</Title>
        <Subtitle>{product.subtitle}</Subtitle>
        <Price>
          <Money amount={product.price} />
        </Price>
      </Text>
    </Container>
  )
}

export default Product

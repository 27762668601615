import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { IProduct } from "../graph"
import Layout from "../layout"
import Product from "../components/product"
import { LangContext } from "../components/l10n"

const Products = styled.section`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`

const order = (a: IProduct, b: IProduct) => {
  if (a.order === b.order) {
    return 0
  }
  return a.order < b.order ? 1 : -1
}

const CategoryTemplate = ({ data }) => {
  const { lang } = useContext(LangContext)

  const products: IProduct[] = data.products.edges
    .map(({ node }) => node)
    .filter(({ node_locale }) => node_locale.indexOf(lang) === 0)
    .sort(order)

  let title: string
  if (data.categories) {
    const category = data.categories.edges
      .map(({ node }) => node)
      .find(({ node_locale }) => node_locale.indexOf(lang) === 0)
    title = category.name
  }

  return (
    <Layout title={title} cart>
      <Products>
        {products.map((product) => (
          <Product key={product.slug} product={product} />
        ))}
      </Products>
    </Layout>
  )
}

export const query = graphql`
  query Category($slug: String!) {
    products: allContentfulProduct(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          node_locale
          name
          subtitle
          slug
          order
          price
          photos {
            title
            fluid(maxWidth: 300, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    categories: allContentfulProductCategory(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          node_locale
          name
        }
      }
    }
  }
`

export default CategoryTemplate
